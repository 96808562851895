.page-not-found {
  background-image: url('../images/not-found/bg-404.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 144px;
  text-align: center;
  .banner {
    margin-bottom: 16px;
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #ff6f6f;
    margin-bottom: 32px;
  }
  .desc {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
  }
}
