$dark-blue: #002245;
$main: #FDD000;
$gray: #f2f4f5;
$hover: #ffc107;
$pink: #ff6f6f;
$black: #1a1a1a;
:root {
  --dark-blue: #002245;
  --main: #FDD000;
  --gray: #f2f4f5;
  --hover: #ffc107;
  --pink: #ff6f6f;
  --black: #1a1a1a;
}

body {
  font-family: "Helvetica";
  font-display: swap;
  color: #1a1a1a;
  background-color: #f2f2f2;
  overflow-x: hidden;
  &.nav-is-show {
    overflow: hidden;
    max-height: 100vh;
  }
}
img {
  max-width: 100%;
}
.btn {
  transition: color .3s ease-in-out,background-color .3s ease-in-out,border-color .3s ease-in-out,box-shadow .3s ease-in-out;
}
.btn,
select,
textarea,
input,
button {
  cursor: pointer;
  outline: none;
  &:hover,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
  max-width: 100%;
  box-shadow: none;
}
.btn,
button:hover,
button:focus,
button:active,
button {
  box-shadow: none;
  outline: none;
  cursor: pointer;
  &:disabled {
    color: #66696d;
    cursor: not-allowed;
  }
}

.webform-popup{
  .js-hide {
    display: none !important;
  }
  .form-item {
    margin-bottom: 16px;
    label {
      display: block;
      margin-bottom: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #1a1a1a;
      opacity: 0.3;
    }

    .form-text {
      margin-top: 0;
    }
    .form-item--error-message {
      color: #dc3545;
      font-size: 12px;
      line-height: 1.4;
      margin-top: 4px;
      padding-left: 0 !important;
      strong {
        font-weight: normal;
      }
    }

    input,
    textarea,
    select {
      display: block;
      padding: 14px 16px;
      font-size: 14px;
      line-height: 20px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      background: #fafbfc;
      border: 1px solid #fdd000;
      -webkit-border-radius: 4px;
      border-radius: 4px;
      outline: none;
      @include transition(border-color 0.4s ease);
      @include placeholder {
        color: #1a1a1a;
        opacity: 0.3;
        font-style: 14px;
      }
      &:not(.error):placeholder-shown{
        border: 1px solid #f0f0f0;
      }
      &:not(.error):focus,
      &:not(.error):hover
      {
        box-shadow: none;
        border: 1px solid #fdd000;
      }
      &.error {
        border-color: #dc3545;
      }
    }
    select.webform-entity-select {
      background-image: url(../images/icons/arrow-down.png);
      background-repeat: no-repeat;
      background-position: right 12px center;
      padding-right: 30px;
      border: 1px solid #f0f0f0;
      color: #1a1a1a;
    }
    input[type="text"],input[type="tel"] {
      cursor: text;
    }
    textarea {
      resize: none;
      height: 150px;
      cursor: text;
    }
    &.js-form-type-checkbox {
      flex: inherit;
      max-width: inherit;
      position: inherit;
      width: inherit;
      padding-right: inherit;
      padding-left: inherit;
      input {
        display: none;
      }
      label {
        opacity: 1;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 24px;
        color: #1a1a1a;
        position: relative;
        padding-left: 32px;
        cursor: pointer;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 24px;
          height: 24px;
          background: #fff;
          border: 1.33px solid #fdd000;
          -webkit-border-radius: 4px;
          border-radius: 4px;
          @include transition(background-color 0.4s);
        }
      }
      input:checked + label {
        &:after {
          background-color: #fdd000;
          border-color: #fdd000;
          background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="pajamas:check-sm"><path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M12.5688 3.04496C12.7326 3.20902 12.8247 3.43142 12.8247 3.66329C12.8247 3.89517 12.7326 4.11756 12.5688 4.28162L5.45562 11.3948C5.37351 11.4768 5.27589 11.5417 5.16845 11.5856C5.06101 11.6296 4.94591 11.6517 4.82984 11.6507C4.71377 11.6498 4.59905 11.6257 4.49237 11.58C4.38569 11.5342 4.28918 11.4677 4.20846 11.3843L1.40496 8.48629C1.24508 8.31923 1.15783 8.09567 1.16227 7.86448C1.16672 7.63329 1.2625 7.41325 1.42868 7.25246C1.59487 7.09167 1.81794 7.0032 2.04916 7.00639C2.28037 7.00957 2.50093 7.10415 2.66262 7.26946L4.84779 9.52812L11.3321 3.04496C11.4962 2.8811 11.7186 2.78906 11.9505 2.78906C12.1823 2.78906 12.4047 2.8811 12.5688 3.04496Z" fill="%23191919"/></g></svg>');
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
    &.form-type-webform-image-file {
      .webform-element-description {
        margin-bottom: 8px;
        h4 {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #000;
          margin-bottom: 8px;
        }
      }
      .form-managed-file {
        @include flexbox;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -8px;
        .js-form-file.form-file {
          display: none;
        }
        .webform-managed-file-preview-wrapper {
          padding: 0 8px;
          margin-bottom: 10px;
          -ms-flex: 0 0 25%;
          flex: 0 0 25%;
          max-width: 25%;
          label {
            padding: 5px;
            border: 1px solid rgba(152, 152, 154, 0.2);
            -webkit-border-radius: 4px;
            border-radius: 4px;
            @include transition(border-color 0.4s ease);
            height: 140px;
            @include flexbox;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            &:after {
              top: 8px;
              left: 8px;
            }
            img {
              display: block;
              max-width: 100%;
            }
          }
          input:checked + label {
            border-color: #002245;
          }
        }
        .form-submit {
          margin: 0 8px 10px;
          background-color: #dc3545;
          border-color: #dc3545;
          color: #fff;
          @include transition(background-color 0.4s ease, color 0.4s ease);
          &:hover {
            color: #fff;
            background-color: #c82333;
            border-color: #bd2130;
          }
        }
      }
      & > label {
        display: inline-block;
        margin-bottom: 0;
        background: rgba(242, 242, 242, 0.5);
        border: 1px solid #ebebeb;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #c4c4c4;
        padding: 54px 6px 22px;
        cursor: pointer;
        -ms-transition: border-color 0.4s ease;
        -o-transition: border-color 0.4s ease;
        -moz-transition: border-color 0.4s ease;
        -webkit-transition: border-color 0.4s ease;
        transition: border-color 0.4s ease;
        background-image: url(../images/icons/add-file.svg);
        background-repeat: no-repeat;
        background-position: top 15px center;
        @include transition(background-color 0.4s, color 0.4s);
        &:hover {
          background-color: #002245;
          color: #fff;
        }
      }
    }
    &.form-type-managed-file {
      .webform-element-description {
        margin-bottom: 8px;
        h4 {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #000;
          margin-bottom: 8px;
        }
      }
      .form-managed-file {
        .js-form-file.form-file {
          display: none;
        }
        .form-item {
          margin-bottom: 8px;
          a {
            color: #1a1a1a;
            text-decoration: underline;
            @include transition(color 0.4s ease);
            &:hover {
              color: #fdd000;
            }
          }
        }
        .form-submit {
          margin-bottom: 10px;
          background-color: #dc3545;
          border-color: #dc3545;
          color: #fff;
          @include transition(background-color 0.4s ease, color 0.4s ease);
          &:hover {
            color: #fff;
            background-color: #c82333;
            border-color: #bd2130;
          }
        }
      }

      & > label {
        margin-bottom: 0;
        cursor: pointer;
        display: inline-block;
        padding: 6px 20px;
        background-color: #fdd000;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        color: #000;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        background-image: url("../images/icons/add-file-white.svg");
        background-repeat: no-repeat;
        background-position: left 5px center;
        background-size: 18px;
        padding-left: 30px;
        @include transition(background-color 0.4s, color 0.4s);
        &:hover {
          background-color: #002245;
          color: #fff;
        }
      }
    }
  }
  .form-radios {
    margin-bottom: 16px;
    .form-type-radio {
      margin-bottom: 8px;
      input {
        display: none;
      }
      label {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 20px;
        color: #1a1a1a;
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        &:after {
          content: "";
          position: absolute;
          top: 4px;
          left: 4px;
          width: 12px;
          height: 12px;
          background: #999;
          -webkit-border-radius: 50%;
          border-radius: 50%;
          -ms-transition: background-color 0.4s ease;
          -o-transition: background-color 0.4s ease;
          -moz-transition: background-color 0.4s ease;
          -webkit-transition: background-color 0.4s ease;
          transition: background-color 0.4s ease;
        }
        &:before {
          content: "";
          position: absolute;
          top: 0px;
          left: 0;
          width: 20px;
          height: 20px;
          border: 1px solid #999;
          -webkit-border-radius: 50%;
          border-radius: 50%;
          -ms-transition: border-color 0.4s ease;
          -o-transition: border-color 0.4s ease;
          -moz-transition: border-color 0.4s ease;
          -webkit-transition: border-color 0.4s ease;
          transition: border-color 0.4s ease;
        }
      }
      input:checked + label {
        &:after {
          background-color: #fdd000;
        }
        &:before {
          border-color: #fdd000;
        }
      }
    }
  }
  .form-actions {
    input {
      background: #fdd000;
      border: 1px solid rgba(152, 152, 154, 0.2);
      box-sizing: border-box;
      border-radius: 27px;
      @include transition(all 0.4s);
      font-weight: bold;
      font-size: 14px;
      padding: 12px 10px;
      min-width: 150px;
      &:not(:disabled):hover {
        border: 1px solid rgba(152, 152, 154, 0.2);
        background-color: #ffc107;
        color: #000;
      }
      &:disabled {
        background: #e1e1e1;
        cursor: not-allowed;
        border: 1px solid rgba(152, 152, 154, 0.2);
        color: #98989a;
      }
    }
  }
  .modal-dialog{
    max-width: 500px;
    .modal-content{
      padding-bottom: 16px;
      border-radius: 16px;
      border: 0;
      .hero{
        border-radius: 16px 16px 0 0;
        margin: -16px -16px 16px -16px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 180px;
        img{
          max-width: 100%;
          width: 100%;
        }
      }
      .close{
        display: flex;
        padding: 8px;
        align-items: center;
        position: absolute;
        right: 16px;
        top: 16px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.30);
        z-index: 10;
        width: 26px;
        height: 26px;
        opacity: 1;
      }
      .main-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: -7px;
        margin-left: -7px;
        position: relative;
        .webform-ajax-form-wrapper {
          width: 100%;
        }
        .main-content__title{
          display: flex;
          flex-direction: column;
          max-width: 372px;
          margin: auto;
          align-items: center;
          gap: 10px;
          margin-bottom: 18px;
          align-self: stretch;
          .main-content__title-1{
            color: var(--black-900, #191919);
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            opacity: 0.8;
            margin-bottom: 0;
          }
          .main-content__title-2{
            color: #464646;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0;
            opacity: 0.8;
          }
        }
        form{
          // overflow-y: scroll;
          // height: 404px;
          width: 100%;
          .div-scroll{
            overflow-y: scroll;
            height: 350px;
            width: 100%;
            margin-bottom: 28px;
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar-track {
              -webkit-border-radius: 3px;
              border-radius: 3px;
              background-color: #fff;
              position: absolute;
              left: 100px;
            }
            .selected-option{
              border-color: #fdd000;
              background-color: rgb(232, 240, 254);
            }
            .form-item--error{
              > label{
                color: #dc3545;
                opacity: 1;
              }
            }
            &::-webkit-scrollbar {
              width: 5px;
              background-color: #fff;
              -webkit-border-radius: 3px;
              border-radius: 3px;
              position: absolute;
              left: 100px;
            }
            &::-webkit-scrollbar-thumb {
              -webkit-border-radius: 3px;
              border-radius: 3px;
              background-color: #e6e6e6;
              position: absolute;
              left: 100px;
            }
            fieldset{
              legend{
                span.fieldset-legend{
                  display: block;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 20px;
                  color: #1a1a1a;
                  opacity: 0.3;
                }
              }
              .fieldset-wrapper{
                .form-checkboxes{
                  display: flex;
                  flex-wrap: wrap;
                  gap: 32px;
                  padding-top: 8px;
                  padding-bottom: 8px;
                  height: 40px;
                }
              }
            }
            input{
              &::placeholder{
              font-family: 'Helvetica';
              font-weight: 500;
              }
            }
            .checkbox-div{
              gap:40px;
              padding-top: 8px;
              padding-bottom: 8px;
              height: 40px;
              margin-bottom: 16px;
            }
            >* {
              max-width: 386px;
              margin: auto;
              margin-bottom: 16px;
              padding-left: 7px;
              padding-right: 7px;
              &:last-child{
                margin-bottom: 0px;
              }
            }
            .checkbox-title{
              margin-bottom: 4px;
              span{
                display: block;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                color: #1a1a1a;
                opacity: 0.3;
              }
            }
          }
          .form-actions{
            text-align: center;
          }
        }
      }
    }
    .thankyou-content{
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 372px;
      margin: auto;
      align-self: stretch;
      padding-top: 27px;
      .thankyou-content__title1{
        color: #191919;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        opacity: 0.8;
        margin-bottom: 16px;
      }
      .thankyou-content__title2{
        color: #464646;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 32px;
        opacity: 0.8;
      }
      .btn-action{
        background: #fdd000;
        box-sizing: border-box;
        border-radius: 27px;
        -ms-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
        font-weight: bold;
        font-size: 14px;
        padding: 16px 80px;
        min-width: 150px;
        text-align: center;
        line-height: 18px;
        &:hover {
          background-color: #ffc107;
        }
      }
    }
  }
  @media (min-width:992px) {
    .modal-dialog{
      margin-top: 37.5px;
    }
  }
}
.popup-event{
  .js-hide {
    display: none !important;
  }
  .modal-dialog{
    max-width: 800px;
    margin: 1.75rem auto;
    padding: 0 22.5px;
    .modal-content{
      border-radius: 16px;
      background-color: transparent;
      border: 0;
      .modal-body{
        padding-bottom: 0;
        .popup-content{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: auto;
          align-self: stretch;
          gap: 34px;
          .hero{
            margin: -16px;
            position: relative;
            .close{
              display: flex;
              padding: 8px;
              align-items: center;
              position: absolute;
              right: 16px;
              top: 16px;
              border-radius: 50%;
              background: rgba(0, 0, 0, 0.30);
              z-index: 10;
              &:not(:disabled):not(.disabled):hover {
                opacity: 0.75;
              }
              &:focus{
                outline: 0;
              }
            }
            img{
              max-width: 100%;
              width: 100%;
              max-height: 78.6vh;
              border-radius: 16px;
            }
          }
          .btn-action{
            margin: 0 auto;
            color:#000;
            background: #fdd000;
            box-sizing: border-box;
            border-radius: 27px;
            -ms-transition: all 0.4s;
            -o-transition: all 0.4s;
            -moz-transition: all 0.4s;
            -webkit-transition: all 0.4s;
            transition: all 0.4s;
            font-weight: bold;
            font-size: 14px;
            padding: 10px 32px 10px 40px;
            min-width: 150px;
            text-align: center;
            line-height: 18px;
            display: flex;
            align-items: center;
            gap: 16px;
            box-shadow: 0px 8px 8px 0px #0000000D;
            &:hover {
              background-color: #ffc107;
            }
            svg{
              width: 28px;
              height: 28px;
              background-color: #fff;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  @media (max-width:767.98px) {
    .modal-dialog{
      margin: 0.75rem auto;
      max-width: 437px;
    }
  }
}
.popup-360{
  .js-hide {
    display: none !important;
  }
  .modal-dialog{
    max-width: 834px;
    .modal-content{
      padding: 0 16px 16px 16px;
      border-radius: 16px;
      border: 0;
      .modal-body{
        padding: 0 1rem;
        @include for-sm{
          padding: 0;
        }
      }
      .close{
        display: flex;
        padding: 8px;
        align-items: center;
        position: absolute;
        right: 16px;
        top: 16px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.30);
        z-index: 10;
        width: 26px;
        height: 26px;
        opacity: 1;
        @include for-sm{
          right: 0;
        }
      }
      h3.page-title{
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        margin-top: 56px;
        margin-bottom: 32px;
      }
      .vt-assistance--materials{
        .div-scroll{
          overflow-y: scroll;
          height: 508px;
          width: calc(100% + 25px);
          padding-right: 20px;
          @include for-sm{
            height: 300px;
            width: calc(100% + 12px);
            padding-right: 7px;
          }
          -webkit-overflow-scrolling: touch;
          &::-webkit-scrollbar-track {
            -webkit-border-radius: 3px;
            border-radius: 3px;
            background-color: #fff;
            position: absolute;
            left: 100px;
          }
          &::-webkit-scrollbar {
            width: 5px;
            background-color: #fff;
            -webkit-border-radius: 3px;
            border-radius: 3px;
            position: absolute;
            left: 100px;
          }
          &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 3px;
            border-radius: 3px;
            background-color: #e6e6e6;
            position: absolute;
            left: 100px;
          }
        }
        .modal-download {
          .modal-body .form-actions {
            width: 100%;
            text-align: center;
          }
        }
        .label-content {
          padding: 0 43px 8px 0px;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #000000;
          opacity: 0.5;
          span {
            width: 50%;
            display: block;
            padding-right: 15px;
            padding-left: 15px;
          }
    
          &.is-hidden {
            opacity: 0;
          }
        }
        .search-form {
          padding-top: 0;
          margin-bottom: 0;
          position: relative;
          height: 50px;
          .btn-reset {
            line-height: 46px;
            top: 0;
            right: 0;
            position: absolute;
            background-color: transparent;
            border: none;
            padding: 0;
            width: 43px;
            height: 48px;
    
            line-height: 48px;
            text-align: center;
            span {
              font-size: 14px;
              line-height: 20px;
              display: none;
            }
          }
          .form-control-item,
          & > form {
            padding-right: 43px;
            border: 1px solid rgba(152, 152, 154, 0.2);
            @include border-radius(4px);
            @include flexbox();
            align-items: center;
            .js-form-type-select {
              position: relative;
              z-index: 10;
              padding: 0 16px;
              width: 50%;
              @include flexbox();
              align-items: center;
              height: 48px;
              position: relative;
              &:after {
                content: "";
                border-left: 1px solid rgba(152, 152, 154, 0.2);
                position: absolute;
                top: 50%;
                height: 20px;
                margin-top: -10px;
                right: 0;
              }
              select {
                height: auto;
                background-color: transparent;
                border: none;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url("../images/icons/arrow-down.png");
                background-position: right 7px center;
                background-size: 10px;
                background-repeat: no-repeat;
                width: 207px;
                color: #1a1a1a;
                padding-right: 25px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                padding-left: 15px;
                border-right: 1px solid rgba(152, 152, 154, 0.3);
                @include border-radius(0);
                flex-shrink: 0;
                border: none;
                padding-left: 0;
                width: 100%;
              }
            }
          }
    
          .form-control-item {
            border: none;
            @include transform(translateY(-73px));
            flex-direction: column;
            .desc {
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: #000000;
              opacity: 0.5;
              width: 50%;
              display: block;
              margin-left: auto;
              padding-left: 15px;
              padding-bottom: 8px;
              @include for-sm{
                display: none;
              }
              &.is-hidden {
                opacity: 0;
              }
            }
            .js-form-type-select {
              order: 2;
              padding-left: 50%;
              width: 100%;
              select {
                height: auto;
                background-color: transparent;
                border: none;
                overflow:hidden;
                text-overflow: ellipsis;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url("../images/icons/arrow-down.png");
                background-position: right 7px center;
                background-size: 10px;
                background-repeat: no-repeat;
                width: 207px;
                color: #1a1a1a;
                padding-right: 25px;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                padding-left: 15px;
                border-right: 1px solid rgba(152, 152, 154, 0.3);
                @include border-radius(0);
                flex-shrink: 0;
                border: none;
                padding-left: 15px;
                width: 100%;
              }
            }
          }
          .form-actions {
            display: none;
          }
        }
        .table-box {
          margin-bottom: 24px;
          table {
            font-size: 14px;
            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;
            border: 1px solid rgba(152, 152, 154, 0.2);
            -webkit-border-radius: 4px;
            border-radius: 4px;
            tr td,
            tr th {
              padding: 16px;
              vertical-align: middle;
            }
            tr {
              background-color: #ffffff;
              &:nth-child(even) {
                background-color: #fafbfb;
              }
              & + tr {
                border-top: 1px solid rgba(152, 152, 154, 0.2);
              }
              th {
                background-color: #fdd000;
                color: $dark-blue;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                padding-top: 12px;
                padding-bottom: 12px;
                text-align: center;
                &:first-child {
                  width: 340px;
                }
                &:last-child {
                  width: 189px;
                }
                & + th {
                  border-left: 1px solid rgba(152, 152, 154, 0.2);
                }
              }
              td {
                text-align: center;
                color: #707781;
                line-height: 22px;
    
                & + td {
                  border-left: 1px solid rgba(152, 152, 154, 0.2);
                }
                span {
                  margin-left: 4px;
                  color: rgba(112, 119, 129, 0.5);
                }
                .link {
                  @include transition(color 0.4s ease);
                  &:hover {
                    color: $hover;
                  }
                }
                &:last-child {
                  width: 48px;
                }
                &:last-child,
                &:first-child {
                  text-align: center;
                }
                .icon {
                  margin-left: 14px;
                  max-width: none;
                }
              }
            }
          }
        }
        .calculator-form {
          padding: 32px 15px 32px;
          .nav-tabs {
            width: 460px;
            max-width: 100%;
            margin: 0 auto 45px;
            border: 1px solid #c8cfd6;
            -webkit-border-radius: 24px;
            border-radius: 24px;
            padding: 1px;
            @include flexbox();
            li {
              width: 50%;
              margin-bottom: 0;
              a {
                border: none;
                width: 100%;
                text-align: center;
                font-size: 14px;
                font-weight: bold;
                line-height: 20px;
                padding: 12px 32px;
                color: #002245;
                display: block;
                -webkit-border-radius: 22px;
                border-radius: 22px;
                @include transition(background-color 0.4s ease);
    
                &.active {
                  background-color: #fdd000;
                }
              }
            }
          }
          .radio-group {
            width: 416px;
            max-width: 100%;
            margin: 0 auto 32px;
            border: 1px solid #c8cfd6;
            -webkit-border-radius: 24px;
            border-radius: 24px;
            padding: 1px;
            @include flexbox();
            label {
              cursor: pointer;
              margin-bottom: 0;
              width: 50%;
            }
            input {
              display: none;
            }
            input:checked + span {
              background-color: #fdd000;
            }
            span {
              width: 100%;
              text-align: center;
              font-size: 14px;
              font-weight: bold;
              line-height: 20px;
              padding: 12px 32px;
              color: #002245;
              display: block;
              -webkit-border-radius: 22px;
              border-radius: 22px;
              @include transition(background-color 0.4s ease);
            }
          }
          .desc {
            text-align: center;
            line-height: 22px;
            font-weight: 700;
            font-size: 16px;
            color: #1a1a1a;
            margin-bottom: 32px;
          }
          .form-controls {
            @include flexbox();
            align-items: center;
            justify-content: center;
            .control-item {
              padding: 0 15px;
              label {
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                color: #1a1a1a;
                margin-right: 21px;
                margin-bottom: 0;
              }
              input {
                width: 100px;
                max-width: 100%;
                border: 1px solid rgba(152, 152, 154, 0.2);
                -webkit-border-radius: 4px;
                border-radius: 4px;
                text-align: center;
                height: 48px;
              }
            }
            input[type="submit"] {
              margin-left: 15px;
              min-width: 150px;
              padding: 0 25px;
              height: 48px;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: #002245;
              @include transition(background-color 0.4s ease);
              border: none;
              -webkit-border-radius: 24px;
              border-radius: 24px;
              background-color: #fdd000;
              cursor: pointer;
              &:hover {
                background-color: $hover;
              }
            }
          }
        }
        .result {
          padding-bottom: 32px;
          .btn-collapse {
            margin-bottom: 20px;
            padding: 12px 26px 12px 32px;
            background-color: transparent;
            border: none;
            font-size: 24px;
            line-height: (28/ 24);
            color: #707781;
            text-align: left;
            font-weight: bold;
            @include flexbox();
            svg {
              margin-top: 18px;
              flex-shrink: 0;
            }
            strong {
              color: $dark-blue;
            }
          }
          .price-box{
            padding-bottom: 32px;
            display:flex;
            flex-direction: column;
            align-items: flex-end;
            gap:16px;
            .price{
              color:#002245;
              display: flex;
              gap: 16px;
              align-items: center;
              font-weight: 500;
              font-size: 18px;
              line-height: 20px;
              span{
                font-weight: 700;
                font-size: 32px;
                line-height: 39px;
              }
            }
            .note{
              color: #737373;
              font-size: 12px;
              line-height: 20px;
            }
          }
          .button-box {
            text-align: center;
            .btn-submit {
              width: 192px;
              height: 50px;
              padding: 14px 25px;
              -webkit-border-radius: 24px;
              border-radius: 24px;
            }
          }
        }
        @media (max-width:767.98px){
          .label-content {
            padding: 0;
            display: none;
            span {
              padding: 0 15px 8px;
              width: 100%;
            }
          }
          .search-form {
            height: auto;
            margin-bottom: 24px;
            .btn-reset {
              display: block;
              line-height: 46px;
              position: static;
              width: 100%;
              border: 1px solid $dark-blue;
              color: $dark-blue;
              @include transition(
                background-color 0.4s ease,
                border-color 0.4s ease
              );
              -webkit-border-radius: 24px;
              border-radius: 24px;
              span {
                display: inline-block;
              }
              &:hover {
                background-color: $hover;
                border-color: transparent;
              }
            }
            .form-control-item,
            & > form {
              border: none;
              -ms-flex-wrap: wrap;
            flex-wrap: wrap;
              padding-right: 0;
              .js-form-type-select {
                width: 100%;
                margin-bottom: 8px;
                border: 1px solid rgba(152, 152, 154, 0.2);
                -webkit-border-radius: 4px;
                border-radius: 4px;
                &:after {
                  content: none;
                }
              }
            }
    
            .form-control-item {
              @include transform(translateY(0));
              .desc {
                width: 100%;
                padding: 0 15px 8px;
              }
              .js-form-type-select {
                padding-left: 15px;
                select {
                  padding-left: 0;
                }
              }
            }
          }
          .calculator-form {
            padding: 0 0 16px 0;
            .desc {
              margin-bottom: 16px;
            }
            .nav{
              margin-bottom: 25px;
              .nav-item{
                .nav-link{
                  padding: 12px 0;
                }
              }
            }
            .form-controls {
              width: 185px;
              max-width: 100%;
              flex-direction: column;
              margin: 0 auto;
              .control-item {
                width: 100%;
                margin-bottom: 8px;
                padding: 0;
                @include flexbox();
                justify-content: space-between;
                align-items: center;
                label {
                  margin-right: 10px;
                }
                input {
                  flex-shrink: 0;
                }
              }
              input[type="submit"]{
                margin-top: 8px;
              }
            }
          }
          .result {
            padding-top: 16px;
            .btn-collapse {
              padding-left: 0;
              padding-right: 0;
              font-weight: 500;
              font-size: 18px;
              line-height: 130%;
              align-items: center;
              margin-bottom: 12px;
              span {
                padding-right: 25px;
              }
              svg {
                margin-top: 0;
              }
            }
            .price-box{
              .price{
                gap: 14px;
                flex-wrap: wrap;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width:992px) {
    .modal-dialog{
      margin-top: 37.5px;
    }
  }
}

.sliding-popup-bottom,
.sliding-popup-bottom#sliding-popup {
  position: fixed;
  z-index: 1020;
  left: 8px;
  right: 8px;
  bottom: 8px !important;
  width: auto !important;
  background: transparent !important;
  .popup-content {
    width: 856px;
    max-width: 100%;
    padding: 16px;
    @include flexbox;
    background: #f2f2f2;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    margin: 0;
    .eu-cookie-compliance-message {
      max-width: none;
      padding-right: 24px;
      p {
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        display: block;
        margin-bottom: 0;
      }
    }

    .eu-cookie-compliance-buttons {
      flex-shrink: 0;

      .agree-button {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        padding: 8px 20px;
        background-color: #fdd000;
        border: none;
        -webkit-border-radius: 16px;
        border-radius: 16px;
        display: block;
        margin: 0 auto 12px;
        @include transition(background-color 0.4s ease, color 0.4s ease);
        &:hover {
          background-color: $dark-blue;
          color: #fff;
        }
      }

      .decline-button {
        display: block;
        margin: 0 auto;
        font-size: 12px;
        line-height: 16px;
        padding: 0;
        border: none;
        text-decoration-line: underline;
        color: #484848;
        background-color: transparent;
        @include transition(color 0.4s ease);
        &:hover {
          color: #fdd000;
        }
      }
    }
  }
}
img[data-align="center"] {
  display: block;
  margin: 0 auto;
}
img[data-align="left"] {
  display: block;
  margin-right: auto;
}
img[data-align="right"] {
  display: block;
  margin-left: auto;
}
a:hover,
a:focus,
a {
  text-decoration: none;
  outline: none;
}

.form-note {
  font-size: 12px;
  line-height: 16px;
  color: #1a1a1a;
  opacity: 0.6;
  margin-bottom: 16px;
  @include flexbox;
  svg {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 8px;
  }
  &--medium {
    font-size: 16px;
    line-height: 22px;
  }
  a {
    @include transition(color 0.4s ease);
    color: #1a1a1a;
    text-decoration-line: underline;
    &:hover {
      color: $hover;
    }
  }
}

.js-form-item-product-id {
  display: none;
}
.btn-more,
ul.js-pager__items.pager {
  padding: 0;
  padding-bottom: 32px;
  list-style: none;
  @include flexbox;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  .button,
  .btn-load-more a.vt-btn {
    font-size: 14px;
    @include flexbox;
    -ms-align-items: center;
    -ms-justify-content: space-between;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 13px 22px;
    border: 2px solid #1a1a1a;
    border-radius: 27px;
    width: 224px;
    max-width: 100%;
    color: #1a1a1a;
    font-weight: 700;
    @include transition(all 0.4s ease);
    &:hover {
      background-color: #fddc00;
      color: #1a1a1a;
      border-color: transparent;
    }
  }
}
.sort-content {
  margin-bottom: 22px;
  @include flexbox();
  -ms-align-items: center;
  -ms-justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  .total {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1a1a1a;
  }
  .sort {
    flex-shrink: 0;
    .btn-dropdown {
      display: inline-block;
      padding: 0;
      font-size: 14px;
      line-height: 20px;
      color: #1a1a1a;
      @include transition(color 0.4s ease);
      svg {
        margin-right: 10px;
      }
      &:after {
        content: none;
      }
    }
    .sort-list {
      border: none;
      -webkit-box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
      box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
      background-color: #fff;
      padding: 6px;
      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $dark-blue;
        padding: 10px 15px;
        @include border-radius(0);
        @include transition(all 0.4s ease);
        &:hover {
          background-color: $hover;
        }
        &.active {
          background-color: $main;
        }
      }
    }
  }
}
.modal {
  z-index: 2050;
}
.modal-backdrop {
  z-index: 2040;
}

.checkbox-control {
  label {
    display: block;
    margin-bottom: 0;
    @include flexbox();
    -ms-align-items: center;
    align-items: center;
    cursor: pointer;
    .check-icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      border: 2px solid #002245;
      opacity: 0.7;
      margin-right: 15px;
      @include border-radius(4px);
      -ms-flex-shrink: 0;
      flex-shrink: 0;
    }
    .text {
      font-size: 14px;
      line-height: 20px;
      color: $dark-blue;
    }
    input:checked + .check-icon {
      opacity: 1;
      background-color: $dark-blue;
      background-image: url("../images/icons/check.png");
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: center;
    }
    input:checked + .check-icon + .text {
      font-weight: 500;
    }
  }
}
.view-user,
section {
  overflow: visible;
  overflow-x: clip;
}
h1,
.page-title {
  font-size: 42px;
  line-height: (50 / 42);
  margin-bottom: 16px;
  font-weight: bold;
}
h2,
.section-title {
  font-size: 32px;
  line-height: (40 / 32);
  margin-bottom: 16px;
  font-weight: bold;
}
h3,
.sub-title {
  font-weight: bold;
  font-size: 28px;
  line-height: (34 / 28);
  margin-bottom: 16px;
}
h4,
.box-title {
  font-size: 24px;
  line-height: (32 / 24);
  margin-bottom: 16px;
  font-weight: bold;
}
.title-bold {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #1a1a1a;
  margin-bottom: 16px;
}
.page-title,
.section-title,
.sub-title,
.box-title {
  color: $dark-blue;
}

.btn-submit {
  cursor: pointer;
  color: #1a1a1a;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  padding: 16px 32px;
  border: none;
  @include border-radius(27px);
  background-color: $main;
  font-weight: 500;
  @include transition(all 0.4s ease);
  &:hover {
    color: #1a1a1a;
    background-color: $hover;
  }
}
.btn-viewmore {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #1a1a1a;
  border-bottom: 1px solid #1a1a1a;
  @include transition(all 0.3s ease);
  &:hover {
    color: $hover;
    border-color: $hover;
  }
}
.slick-slider {
  .slick-track {
    min-width: 100%;
  }
}
.slider-content {
  width: 100%;
  &.slick-dotted.slick-slider {
    margin-bottom: 32px;
  }
  .slick-arrow {
    background-size: contain;
    width: 24px;
    height: 22px;
    z-index: 10;
    background-repeat: no-repeat;
    background-position: center;
  }
  .slick-prev {
    background-image: url("../images/icons/left-arrow.svg");

    &:before {
      content: none;
    }
  }
  .slick-next {
    background-image: url("../images/icons/right-arrow.svg");
    &:before {
      content: none;
    }
  }
  .slick-dots {
    bottom: -32px;
    @include flexbox();
    align-items: center;
    justify-content: center;
    li {
      display: block;
      width: 8px;
      height: 8px;
      & + li {
        margin-left: 8px;
      }
    }
    .slick-active button {
      background-color: #161616;
    }
    button {
      padding: 0;
      margin: 0 auto;
      width: 8px;
      height: 8px;
      @include border-radius(50%);
      background-color: #c4c4c4;
      &:before {
        content: none;
      }
    }
  }
}
.slider-wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 32px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15px;
    background-color: inherit;
    z-index: 4;
  }
  &:before {
    left: auto;
    right: 0;
  }
  .slider-controls {
    .btn-slider {
      padding: 0;
      background-color: transparent;
      border: none;
      position: absolute;
      top: 50%;
      z-index: 5;
      @include transform(translateY(-50%));
      left: -15px;
      @include transition(all 0.4s ease);
      &--next {
        left: auto;
        right: -15px;
      }
      &.swiper-button-disabled {
        opacity: 0;
        visibility: hidden;
      }
    }
    .swiper-pagination {
      width: 100%;
      left: 0;
      right: 0;
      bottom: -32px;
      @include flexbox;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      .swiper-pagination-bullet {
        padding: 0;
        width: 8px;
        height: 8px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #c4c4c4;
        margin: 0;
        padding: 0;
        outline: none;
        opacity: 1;
        @include transition(background-color 0.4s ease);
        & + .swiper-pagination-bullet {
          margin-left: 8px;
        }
        &.swiper-pagination-bullet-active {
          background-color: #161616;
        }
      }
    }
  }
}
.page-container {
  padding-top: 108px;
  & > .row {
    & > section {
      width: 100%;
      padding: 0;
    }
  }
}
input,
textarea,
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-item--error-message {
  margin-top: 8px;
  display: block;
  font-size: 12px;
  color: #dd2727;
  line-height: 18px;
  padding-left: 16px;
  font-weight: normal;
  strong {
    font-weight: normal;
  }
}

#views-exposed-form-new-articles-block-1 .form--inline .form-actions .form-submit {
  visibility: hidden;
}
.view-id-san_pham_cau_kien_loyalty .form-submit,
.js-hide,
.rating .field--name-field-rating legend,
.rating .field--name-field-rating .fieldset-legend,
.view-new-articles .form-submit,
.view-san-pham-hoan-thien .form-submit,
.view-san-pham-cau-kien .form-submit,
.form-item-title-article .journey-landing .contact-form .form-contact form .form-item textarea,
.journey-landing .contact-form .form-contact form .form-item .form-item-title-article textarea,
.form-item-field-solution-type-target-id .form-select,
.form-item-field-product-component-tags-target-id .form-select,
.form-item-field-tags-target-id .form-select,
.form-item-field-cate-overivew-article-target-id .form-select,
#views-exposed-form-new-articles-block-1 .form--inline .form-actions,
.form-item-title-article .form-text,
.view-empty .sort-content,
.visually-hidden,
#pageHome .form-item-field-solution-type-target-id,
#pageHome .form-item-field-product-component-tags-target-id {
  display: none;
}
.main-container > .row > section {
  padding: 0;
  width: 100%;
}
@mixin base-lg {
  .page-container {
    padding-top: 64px;
  }
  .page-banner {
    padding: 100px 0;
  }
}
@mixin base-md {
  .page-banner {
    padding: 80px 0;
  }
}
@mixin base-sm {
  .sliding-popup-bottom {
    .popup-content {
      -ms-flex-direction: column;
      flex-direction: column;
      .eu-cookie-compliance-message {
        padding-right: 0;
        margin-bottom: 0.5rem;
      }
    }
  }
  .slider-wrapper .slider-controls .btn-slider {
    display: none;
  }
  h1,
  .page-title {
    font-size: 35px;
  }
  h2,
  .section-title {
    font-size: 27px;
  }
  h3,
  .sub-title {
    font-size: 23px;
  }
  .title-bold {
    font-size: 20px;
  }

  .page-container {
    padding-top: 54px;
  }
  .section-title {
    br {
      display: none;
    }
  }
  .slider-content {
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
  }
  .page-banner {
    height: 200px;
    .title {
      font-size: 28px;
    }
  }
  .form,
  form {
    .js-form-item {
      input,
      select,
      textarea {
        font-size: 16px;
      }
    }
  }

  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select,
  select:focus,
  textarea {
    font-size: 16px !important;
  }
}
@mixin base-grid {
  // grid
  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }
  @media (min-width: 1230px) {
    .container {
      max-width: 1210px;
    }
  }
}
.style-table{
  overflow: auto;
  max-width: 100%;
  max-height: 70vh;
  @include for-sm{
    max-height: 50vh;
  }
}