.view.view-block-ads.view-id-block_ads {
  background-color: #f2f2f2;
}
.block-views-blockblock-ads-block-1.block-views-blockblock-ads-block-1 {
  margin-bottom: 0;
}
.view-block-ads {
  .js-pager__items.pager {
    padding-top: 0px;
    padding-bottom: 32px;
  }
}
.home-post-qc {
  padding-top: 32px;
  padding-bottom: 16px;
  background-color: #f2f2f2;
  .section-title {
    margin-bottom: 32px;
  }
  & + .home-post-qc {
    padding-top: 0;
  }
  .t-wrap-block-blog {
    padding-left: 15px;
    padding-right: 15px;
  }
  .btn-viewmore {
    font-size: 14px;
    line-height: 17px;
    color: #1a1a1a;
    padding: 16px 24px;
    @include flexbox();
    align-items: center;
    justify-content: space-between;
    width: 224px;
    margin: 0 auto;
    max-width: 100%;
    border: 2px solid #1a1a1a;
    -webkit-border-radius: 27px;
    border-radius: 27px;
    @include transition(all 0.4s ease);
    svg {
      @include transition(transform 0.4s ease);
    }
    &:hover {
      background-color: $main;
      svg {
        @include transform(translateX(10px));
      }
    }
  }
  .block-blog {
    margin-right: -15px;
    margin-left: -15px;
    .blog-item {
      padding: 0 15px;
      margin-bottom: 30px;
    }
    .vt-blog-wrap {
      height: 324px;
      padding: 0;
      width: auto;
      position: relative;
      .btn-full {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
      }
      .wrap-img {
        width: 100%;
        height: 100%;
        img {
          -webkit-filter:blur(5px);
          filter:blur(5px);
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }
      .vt-blog {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        .vt-overplay {
          background-color: #000;
          opacity: 0.7;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          pointer-events: none;
          @include transition(all 0.4s ease);
        }

        .content-wrap {
          z-index: 2;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          padding: 50px;
          color: #fff;
          -ms-transition: all .5s ease-in-out;
          -o-transition: all .5s ease-in-out;
          -moz-transition: all .5s ease-in-out;
          -webkit-transition: all .5s ease-in-out;
          transition: all .5s ease-in-out;
        }

        .vt-icon {
          pointer-events: none;
          @include transition(all 0.5s ease-in-out);
          img {
            max-height: 99px;
            max-width: 125px;
            -o-object-fit: contain;
            object-fit: contain;
          }
        }

        .vt-text-blog {
          position: absolute;
          left: 0;
          width: 100%;
          padding: 20px 50px 50px;
          bottom: -100px;
          -ms-transition: all .5s ease-in-out;
          -o-transition: all .5s ease-in-out;
          -moz-transition: all .5s ease-in-out;
          -webkit-transition: all .5s ease-in-out;
          transition: all .5s ease-in-out;
          .vt-btn {
            align-items: center;
            padding: 15px 36px;
            font-size: 14px;
            line-height: 20px;
            border: 2px solid $main;
            color: $main;
            width: 224px;
            @include flexbox;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-border-radius: 27px;
            border-radius: 27px;
            font-weight: bold;
            opacity: 0;
            @include transition(all 0.4s ease);
            &:hover {
              border-color: $dark-blue;
              background-color: transparent;
            }
            svg {
              flex-shrink: 0;
              margin-left: 5px;
              path {
                fill: $main;
              }
            }
          }
          .post-title {
            min-height: auto;
            @include blockEllipsis(2);
            color: #fff;
            font-size: 28px;
            line-height: 32px;
            font-weight: bold;
            margin-bottom: 20px;
            height: 64px;
            @include transition(color 0.3s ease);
            a {
              color: inherit;
              @include transition(color 0.3s ease);
            }
          }
        }
        &:hover {
          .content-wrap {
            top: -100px;
          }
          //.vt-overplay {
          //  opacity: 0.9;
          //  top: 0;
          //}
          //&:after {
          //  opacity: 0;
          //}
          .vt-btn {
            opacity: 1;
          }
          .vt-icon {
            opacity: 0;
          }
          .vt-text-blog {
            background-color: transparent;
            //bottom: 0;
            .vt-h2 {
              color: #1a1a1a;
            }
            .post-title {
              color: $main;
            }
          }
        }
      }
    }
  }
}
@mixin home-post-qc-md {
  .home-post-qc {
    .block-blog .vt-blog-wrap .vt-text-blog .vt-btn {
      width: auto;
    }
    .block-blog .vt-blog-wrap .vt-blog .vt-text-blog .post-title {
      font-size: 26px;
    }
    .block-blog .vt-blog-wrap .vt-blog .vt-text-blog {
      padding: 20px 30px;
      bottom: -80px;
    }
  }
}
@mixin home-post-qc-sm {
  .home-post-qc {
    padding-bottom: 40px;
    .section-title {
      margin-bottom: 16px;
    }
    .block-blog {
      margin: 0 -7.5px;
      .blog-item {
        padding: 0 7.5px;
        margin-bottom: 16px;
      }
      .vt-blog-wrap {
        height: 176px;
        .wrap-img {
          height: 176px;
        }
        .vt-blog {
          .content-wrap {
            padding: 24px 16px;
          }

          .vt-icon {
            img {
              max-height: 54px;
              max-width: 79px;
            }
          }
          .vt-text-blog {
            bottom: 0;
            background-color: transparent;
            padding: 0px 16px;
            .vt-btn {
              background-color: transparent;
              width: 40px;
              height: 40px;
              padding: 0;
              opacity: 1;
              border: none;
              font-size: 0;
              position: absolute;
              left: 16px;
              bottom: 24px;
              svg {
                width: 100%;
                height: 100%;
                margin-left: 0;
                path {
                  fill: #fff;
                }
              }
            }
            .post-title {
              margin-bottom: 16px;
              font-size: 14px;
              line-height: 20px;
              height: 40px;
              font-weight: 700;
              color: #fff;
            }
          }

          &:hover {
            .content-wrap {
              top: 0;
            }
            .vt-btn {
              opacity: 1;
            }
            .vt-icon {
              opacity: 1;
              top: 0px;
            }
          }

        }
      }
    }
    .block-blog .vt-blog-wrap .vt-blog .vt-overplay {
      opacity: 0.7;
      background-color: #000;
    }
    .block-blog .vt-blog-wrap {
      height: 176px;
    }
  }
}
@mixin home-post-qc-xs {
  .view-block-ads {
    .js-pager__items.pager {
      padding-top: 0px;
      padding-bottom: 16px;
    }
  }
  .home-post-qc {
    padding-top: 32px;
    padding-bottom: 6px;
    .block-blog .vt-blog-wrap .vt-blog .vt-text-blog .vt-btn {
      width: 34px;
      height: 34px;
      font-size: 0;
      padding: 0;
      svg {
        width: 34px;
        height: auto;
      }
    }
    .block-blog .vt-blog-wrap .vt-text-blog .vt-h2 {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
@mixin home-post-qc-sp {
  .home-post-qc {
    .block-blog {
      .blog-item {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
