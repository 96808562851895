.t-main-header {
  padding: 0;
  z-index: 1111;
  .btn-cart {
    @include flexbox;
    padding: 7px 8px;
    border: 1px solid #002245;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #000000;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    position: absolute;
    top: 60px;
    right: 15px;
    background-color: #FDDC00;
    @include transition(all 0.4s ease);
    &:hover {
      background-color: #000;
      color: #fff;
      svg path {
        fill: #fff;
      }
    }
    svg {
      
      path {
      @include transition(fill 0.4s ease);
    }}
    span {
      margin-left: 8px;
    }
    i {
      font-style: normal;
      color: #FF6F6F;
    }
  }
}

.block-mpire-search {
  position: fixed;
  top: 0;
  z-index: 1000;
  right: 115px;
}
.icon-menu-res {
  display: none;
}
.block-mpire-search .block-title {
  display: none;
}

.block-mpire-search .vt-input-search input {
  margin-top: 0;
  background-color: #f2f2f2;
  border: none;
  border-radius: 0;
  height: 44px;
  font-size: 14px;
  padding: 0 24px;
  width: 307px;
  display: inline-block;
}

.block-mpire-search .btn-search {
  position: absolute;
  top: 12px;
  right: 24px;
  pointer-events: none;
}

.block-mpire-search .vt-btn-search {
  position: absolute;
  top: 0;
  right: 0;
}

.block-mpire-search .vt-btn-search .form-group {
  margin-bottom: 0;
}

.block-mpire-search .vt-btn-search input {
  z-index: 1111;
  height: 44px;
  width: 44px;
  opacity: 0;
  display: block;
  cursor: pointer;
}
.block-logomenudropdown-2 {
  position: fixed;
  top: 55px;
  z-index: 1000;
  left: 50px;
  img {
    max-height: 45px;
  }
  .vt-btn-dropdown {
    display: none;
  }
}
.region-header {
  position: fixed;
  top: 0;
  width: 100%;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.region-header.t-hide {
  opacity: 0;
  -ms-transform: translateY(-120px);
  -o-transform: translateY(-120px);
  -moz-transform: translateY(-120px);
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
}

.region-header.t-show {
  opacity: 1;
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.region-header.show {
  opacity: 1;
  -ms-transform: none;
  -o-transform: none;
  -moz-transform: none;
  -webkit-transform: none;
  transform: none;
}

.vt-menu {
  position: relative;
  padding: 0;
  height: 44px;
  line-height: 44px;
  display: block;
  background-color: #ffffff;
}

.vt-menu .text-dk {
  display: inline-block;
  font-size: 14px;
}

.vt-menu .text-dk a {
  font-weight: bold;
  color: #1a1a1a;
  float: left;
}

.vt-menu .text-dk a:last-child::before {
  content: "/";
  margin-right: 5px;
  margin-left: 5px;
}

.vt-menu .text-dk a:hover {
  color: #fddc00;
}

.vt-menu .text-dk p {
  margin-bottom: 0;
  float: left;
  margin-right: 5px;
}

.vt-menu .on-res.text-dk {
  display: none;
}

.vt-menu .on-res.text-dk p {
  display: none;
}

.vt-menu .on-res.text-dk.show {
  display: block;
  position: absolute;
  top: 32px;
  left: 32px;
  line-height: 100%;
}

.vt-menu .on-res.text-dk.show a {
  color: #ffffff;
}

.vt-menu .navbar-brand {
  width: 26%;
  max-width: 170px;
  position: absolute;
  top: 0;
  left: 46px;
  padding: 0;
  margin: 0;
}

.vt-menu .navbar-brand .img-logo-sg-bg {
  max-width: 162px;
}

.vt-menu .navbar-brand .img-logo-sg {
  margin-left: -4px;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.vt-menu .vt-bar-top {
  width: 100%;
  height: 64px;
  background-color: #fddc00;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  position: absolute;
  top: 44px;
  .btn-cart {
    display: none;
    margin-left: 15px;
    position: static;
    span {
      margin-left: 4px;
    }
  }
}

.vt-menu .navbar-toggler {
  padding: 0;
  color: #1a1a1a;
  border: none;
}

.vt-menu .vt-link-menu {
  position: absolute;
  top: 44px;
  right: 150px;
}

.vt-menu .vt-link-menu .nav-item {
  position: relative;
}

.vt-menu .wrap-search {
  position: relative;
}

.vt-menu .vt-form-search {
  margin-right: 150px;
  position: relative;
}

.vt-menu .vt-form-search input {
  background-color: #f2f2f2;
  border: none;
  border-radius: 0;
  height: 44px;
  font-size: 14px;
  padding: 0 24px;
  width: 307px;
}

.vt-menu .btn-search {
  position: absolute;
  right: 24px;
  top: 8px;
  padding: 0;
}

.vt-menu .navbar-collapse {
  padding-left: 50px;
  justify-content: space-between;
}

.vt-menu .navbar-logo {
  position: absolute;
  left: 266px;
}

.vt-menu .nav-item a {
  display: block;
  padding: 0.5rem 1rem;
  font-size: 14px;
  line-height: 16px;
}

.vt-menu .nav-item a:hover {
  color: #ffffff;
}

.vt-menu .nav-item .is-active {
  font-weight: 500;
  opacity: 1;
}

.vt-menu .form-lang {
  position: relative;
}

.vt-menu .form-lang svg {
  position: absolute;
  right: 20px;
  top: 20px;
}

.vt-menu .form-lang select {
  display: none;
  font-size: 14px;
  border-radius: 0;
  height: 44px;
  width: 100px;
  border: none;
  background-color: #1a1a1a;
  color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 24px;
}

.vt-menu .icon-wrap {
  position: absolute;
  right: 490px;
}

.vt-menu .icon-wrap a:not(:last-child) {
  margin-right: 32px;
}
.region-header .block-logomenudropdown-2,
.region-header .navbar-brand,
.region-header .icon-menu-des {
  @include transition(all 0.4s ease);
}
.region-header.show .block-logomenudropdown-2,
.region-header.show .navbar-brand,
.region-header.show .icon-menu-des {
  display: none;
  opacity: 0;
  visibility: hidden;
}
.region-header.show .vt-menu .vt-bar-top .btn-cart {
  display: none;
}

.region-header.show .vt-link-menu {
  height: 100vh;
}

.region-header.show .vt-menu .vt-bar-top {
  background-color: #002245;
  padding: 32px;
  height: unset;
}

.region-header.show .icon-menu-res,
.region-header.show .language-switcher-language-url {
  display: block;
}

.region-header.show .language-switcher-language-url {
  right: unset;
  left: 32px;
  top: 76vh;
}

.region-header.show .language-switcher-language-url .btn-danger {
  display: none;
}

.region-header.show .language-switcher-language-url .language-link.is-active,
.region-header.show .language-switcher-language-url .dropdown-menu {
  display: block;
}

.region-header.show .language-switcher-language-url .dropdown-menu {
  top: 0;
  overflow: unset;
  height: 44px;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
}

.region-header.show .language-switcher-language-url .dropdown-menu li {
  float: left;
  color: transparent;
}

.region-header.show .language-switcher-language-url .dropdown-menu li a {
  width: 44px;
  height: 44px;
  border: 1px solid #fff;
  border-radius: 4px;
  margin-right: 24px;
  display: inline-block;
  position: relative;
  opacity: 0.5;
}

.region-header.show .language-switcher-language-url .dropdown-menu li a:hover {
  color: transparent;
}

.region-header.show
  .language-switcher-language-url
  .dropdown-menu
  li
  a.is-active {
  opacity: 1;
}

.region-header.show
  .language-switcher-language-url
  .dropdown-menu
  li
  a[hreflang="en"]::before {
  content: "EN";
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.region-header.show
  .language-switcher-language-url
  .dropdown-menu
  li
  a[hreflang="vi"]::before {
  content: "VN";
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.region-header.show .block-mpire-search {
  display: block;
  right: unset;
  top: 150px;
  left: 32px;
}


.region-header.show .block-mpire-search .vt-input-search {
  padding-right: 32px;
}

.region-header.show .block-mpire-search .vt-input-search input {
  border-radius: 5px;
  width: 100%;
  max-width: 500px;
}

.region-header.show .block-mpire-search .btn-search {
  right: 48px;
}
.t-main-header {
  .who {
    position: fixed;
    top: 10px;
    left: 50px;
    z-index: 1001;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    .dropdown {
      margin-left: 5px;
      .dropdown-toggle {
        cursor: pointer;
        padding: 0;
        color: $dark-blue;
        font-weight: 500;
        background-color: transparent;
        border: none;
        outline: none;
        svg {
          margin-left: 10px;
        }
        &::after {
          content: none;
        }
      }
    }
  }
  .vt-menu .icon-wrap {
    top: 10px;
  }
  .list-socials {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    @include flexbox();
    align-items: center;
    li {
      line-height: 1;
      width: 24px;
      text-align: center;
      img {
        max-height: 24px;
      }
      a {
        svg {
          path {
            @include transition(all 0.4s ease);
          }
        }
        &:hover {
          svg {
            path {
              fill: $main;
            }
          }
        }
      }
      & + li {
        margin-left: 14px;
      }
    }
  }
  .visually-hidden {
    display: none;
  }
  .block-mpire-search {
    right: 0px;
  }
  .vt-menu .icon-wrap {
    right: 330px;
  }
  .vt-menu .vt-link-menu {
    @include flexbox();
    align-items: center;
    ul {
      list-style: none;
    }
    & > li {
      &.has-child {
        & > a {
          padding-right: 20px;
          box-shadow: none !important;
          background-image: url(../images/icons/arrow-down.png);
          background-size: 10px;
          background-repeat: no-repeat;
          background-position: right 3px center;
        }
      }
      & > a {
        padding: 22px 12px;
        &:before {
          content: "";
          position: absolute;
          height: 2px;
          margin: 0 auto;
          bottom: 0;
          left: 20px;
          right: 20px;
          background: $dark-blue;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transform-origin: 50%;
          transform-origin: 50%;
          -webkit-transition-property: transform;
          transition-property: transform;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
        }
      }
      &.active > a {
        font-weight: bold;
      }
      &:hover > a:before,
      &.active > a:before {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
      & > ul {
        top: 110%;
        left: 0;
        opacity: 0;
        visibility: hidden;
        @include transition(all 0.3s ease);
      }
      &:last-child {
        & > ul {
          left: auto;
          right: 0;
        }
      }
      &:hover > ul {
        opacity: 1;
        visibility: visible;
        top: 90%;
      }
    }
    li {
      position: relative;
      a {
        display: block;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $dark-blue;
      }
      ul {
        padding: 6px 0;
        background-color: #fff;
        z-index: 10;
        @include border-radius(4px);
        position: absolute;
        width: 171px;
        -webkit-box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1),
          0px 0px 1px rgba(10, 31, 68, 0.08);
        box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1),
          0px 0px 1px rgba(10, 31, 68, 0.08);
        li {
          a {
            padding: 15px 18px;
            position: relative;
            z-index: 0;
            &:after {
              content: "";
              position: absolute;
              top: auto;
              left: 0;
              width: 100%;
              height: 0%;
              bottom: 0;
              background-color: $main;
              z-index: -1;
              @include transition(height 0.25s linear);
            }
          }
          ul {
            opacity: 0;
            visibility: hidden;
            top: 0;
            left: 120%;
            @include transition(all 0.4s ease);
          }
          &:hover {
            & > ul {
              left: 100%;
              opacity: 1;
              visibility: visible;
            }
            & > a {
              &:after {
                height: 100%;
                right: auto;
                top: 0;
              }
            }
          }
        }
      }
    }
  }
}



@mixin header-lg {
  .vt-menu .vt-bar-top {
    .btn-cart {
      @include flexbox;
    }
  }
  .t-main-header {
    .btn-cart {
      display: none;
    }
  }
  .block-mpire-search {
    display: none;
  }
  .vt-menu .on-des {
    visibility: hidden;
    display: none;
  }
  .vt-menu .on-res {
    visibility: visible;
    display: block;
  }
  .vt-menu {
    position: relative;
    display: block;
    text-align: right;
    padding: 0;
  }
  .vt-menu .navbar-brand .logo-mobile {
    display: block;
  }

  .vt-menu .navbar-brand .logo-des {
    display: none;
  }

  .vt-menu .navbar-logo {
    left: 138px;
  }

  .vt-menu .vt-link-menu {
    position: relative;
    top: unset;
    right: unset;
    text-align: left;
  }

  .vt-menu .vt-form-search {
    margin-right: 0px;
  }

  .vt-menu .vt-form-search input {
    width: 100%;
  }

  .vt-menu .navbar-brand {
    display: block;
    left: 12px;
  }

  .vt-menu .navbar-collapse {
    justify-content: unset;
    padding: 32px;
    padding-top: 150px;
    background-color: #002245;
  }

  .vt-menu .nav-item a {
    display: inline-block;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    padding-left: 0;
    opacity: 0.6;
  }

  .vt-menu .vt-bar-top {
    position: relative;
    top: unset;
  }
  .t-main-header {
    .vt-menu .icon-wrap {
      display: block;
      position: static;
      padding-bottom: 10px;
    }
    .who {
      display: none;
    }
    .block-logomenudropdown-2 {
      top: 8px;
      left: 16px;
    }
    .vt-menu .vt-link-menu {
      padding-bottom: 70px;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      li ul li a:after {
        content: none;
      }
      & > li.has-child > a,
      li a {
        display: inline-block;
        color: #fff;
      }
      li ul li a {
        color: #fff;
        padding: 8px 0;
      }
      & > li > a {
        color: #fff;
        padding: 8px 0;
      }
      & > li.has-child > a {
        background-image: none;
        position: relative;
        &:after {
          content: "\f107";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          position: absolute;
          top: 10px;
          right: 2px;
          color: #fff;
        }
      }
      & > li.active > a,
      li.active > a {
        color: $main;
      }
      li ul {
        position: static;
        padding-left: 20px;
        opacity: 1;
        visibility: visible;
        width: auto;
        background-color: transparent;

        li {
          a {
            color: rgba(255, 255, 255, 0.6);
            padding: 8px 0;
          }
        }
      }
    }
    .region-header.show .language-switcher-language-url {
      top: auto;
      bottom: 20px;
    }

    .vt-menu .navbar-collapse {
      justify-content: unset;
      padding: 0 32px;
      background-color: #002245;
    }
    .region-header.show .vt-menu .vt-bar-top {
      height: 64px;
      padding: 0 16px 0 0;
    }
    .region-header.show .block-mpire-search {
      right: 50px !important;
      top: 10px;
      left: 15px;
      padding-right: 15px;
    }
    .region-header.show .block-mpire-search .vt-input-search {
      padding-right: 0;
    }
    .region-header.show .block-mpire-search .vt-input-search input {
      max-width: none;
      padding-right: 44px;
    }
    .block-mpire-search .vt-btn-search {
      right: 15px;
    }
    .region-header.show .block-mpire-search .btn-search {
      right: 25px;
    }
    .region-header.show .language-switcher-language-url {
      bottom: auto;
      top: 10px;
      left: 15px;
      bottom: auto;
    }
    .region-header.show .language-switcher-language-url .dropdown-menu li a {
      width: 38px;
      margin: 0;
    }
    .region-header.show .language-switcher-language-url .dropdown-menu li + li {
      margin-left: 5px;
    }
    .region-header.show .vt-link-menu {
      height: calc(100vh - 100px);
      overflow-y: scroll;
      padding-bottom: 90px;
      margin-top: 0 !important;
    }

    .region-header.show .vt-link-menu::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .region-header.show .vt-link-menu::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    .region-header.show .vt-link-menu::-webkit-scrollbar-thumb {
      -webkit-border-radius: 3px;
      border-radius: 3px;
      background-color: #fddc00;
    }
  }
}
@mixin header-sm {
  .t-main-header .block-logomenudropdown-2 {
    top: 5px;
  }
  .vt-menu .vt-bar-top {
    height: 54px;
    position: relative;
    top: unset;
  }

  .vt-menu .navbar-brand {
    max-width: 106px;
  }

  .vt-menu .navbar-brand .img-logo-sg-bg {
    width: 100%;
    max-width: 100%;
    margin-top: -25px;
  }

  .vt-menu .navbar-brand .img-logo-sg {
    width: 80%;
  }
}
