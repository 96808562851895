@import "./includes/master/mixins";
// libs
@import "includes/bootstrap";
@import "includes/font-awesome";
@import "includes/swiper";
// global
@import "includes/master/base";
@import "includes/master/font";

// masterpage
@import "includes/master/cta";
@import "includes/master/t-header";
@import "home/home-ads";
@import "includes/master/footer";

@mixin master-media {
  // base grid
  @include base-grid;

  // media queries
  @include for-lg {
    @include base-lg;
    @include header-lg;
    @include footer-lg;
  }

  @include for-md {
    @include base-md;
    @include home-post-qc-md;
    @include footer-md;
  }

  @include for-sm {
    @include base-sm;
    @include header-sm;
    @include home-post-qc-sm;
    @include footer-sm;
  }
  @include for-xs {
    @include home-post-qc-sm;
  }
  @include for-sp {
    @include home-post-qc-sp;
  }
}
.share-block {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

}
.btnShare {
  width: 32px;
  height: 32px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #fdd000;
  border: none;
  padding: 0;
  svg {
    display: block;
    margin: 0 auto;
  }
}

.btnShare + .text {
  margin-bottom: 16px;
  display: inline-block;
  margin-left: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #002245;
}
