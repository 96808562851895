@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.call-to-action {
  .btn-cta {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $dark-blue;
    z-index: 100;
    display: block;
    width: auto;
    padding: 0 10px 0 0;
    height: 40px;
    line-height: 36px;
    @include border-radius(20px);
    -webkit-box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
    box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
    background-color: $main;
    margin: 0;
    @include flexbox();
    align-items: center;
    justify-content: center;
    &.btn-call {
      text-align: center;
      width: auto;
      @include border-radius(20px);
      overflow: hidden;
      right: 15px;
      bottom: 72px;
      position: fixed;
      padding-right: 10px;
      span {
        width: 40px;
        display: inline-block;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: $dark-blue;
        white-space: nowrap;
        @include transition(all 0.4s ease);
        text-align: center;
        width: auto;
      }
      svg {
        width: 40px;
        animation-name: tada;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        path {
          fill: $dark-blue
        }
      }
      
    }
    &.btn-more {
      
      cursor: pointer;
      border: none;
      width: auto;
      background-color: $main;
      -webkit-box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
      box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
      svg {
        width: 40px;
        @include transition(transform 0.4s ease);
      }
      &.active {
        svg {
          @include transform(rotate(135deg));
        }
      }
    }
  } 
  .shortcutes {
    position: fixed;
    z-index: 100;
    bottom: 20px;
    right: 15px;
    button {
      margin-left: auto;
    }
    .box {
      display: none;
      width: 170px;
      -webkit-box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
      box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
      background-color: #fff;
      bottom: 100%;
      right: 0;
      overflow: hidden;
      -webkit-border-radius: 4px;
      border-radius: 4px;
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
          & + li {
            a {
              border-top: 1px solid rgba(0, 34, 69, 0.1);
            }
          }
          a {
            color: #6C798F;
            font-size: 12px;
            display: block;
            padding: 12px 14px;
            @include flexbox();
            align-items: center;
            @include transition(all 0.4s ease);
            .icon {
              width: 20px;
              flex-shrink: 0;
              margin-right: 8px;
              img {
                margin: 0 auto;
                display: block;
              }
            }
            &:hover {
              background-color: #F9F9F9;
              color: #161616;
            }
          }
        }
      }
      .title {
        padding: 5px 14px;
        font-size: 12px;
        line-height: 1.4;
        color: $dark-blue;
        background-color: $main;
        span {
          font-weight: 500;
          display: block;
        }
      }
    }
  }
}


.btn-to-top {
  z-index: 200;
  bottom: 120px;
  right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $dark-blue;
  padding: 0;
  border: none;
  position: fixed;
  @include transition(background-color 0.4s ease);
  display: none;
  svg {
    width: 18px;
    margin-top: -2px;
    height: auto;
  }
  &:hover {
    background-color: $hover;
  }
}