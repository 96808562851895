
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/HelveticaNeue-Bold.eot");
  src: url("../fonts/HelveticaNeue-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/HelveticaNeue-Bold.woff2") format("woff2"),
    url("../fonts/HelveticaNeue-Bold.woff") format("woff"),
    url("../fonts/HelveticaNeue-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("../fonts/HelveticaNeue-Medium.eot");
  src: url("../fonts/HelveticaNeue-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/HelveticaNeue-Medium.woff2") format("woff2"),
    url("../fonts/HelveticaNeue-Medium.woff") format("woff"),
    url("../fonts/HelveticaNeue-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("../fonts/HelveticaNeue.eot");
  src: url("../fonts/HelveticaNeue.eot?#iefix") format("embedded-opentype"),
    url("../fonts/HelveticaNeue.woff2") format("woff2"),
    url("../fonts/HelveticaNeue.woff") format("woff"),
    url("../fonts/HelveticaNeue.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/HelveticaNeue-Light.eot");
  src: url("../fonts/HelveticaNeue-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/HelveticaNeue-Light.woff2") format("woff2"),
    url("../fonts/HelveticaNeue-Light.woff") format("woff"),
    url("../fonts/HelveticaNeue-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SlaitMonolineDEMO";
  src: url("Pacifico-Regular.eot");
  src: url("Pacifico-Regular.eot?#iefix") format("embedded-opentype"),
    url("Pacifico-Regular.woff2") format("woff2"),
    url("Pacifico-Regular.woff") format("woff"),
    url("Pacifico-Regular.ttf") format("truetype"),
    url("Pacifico-Regular.svg#Pacifico-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
