// Mixins
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@mixin inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin flex-direction($var) {
  -webkit-box-orient: $var;
  -webkit-box-direction: $var;
  -ms-flex-direction: $var;
  flex-direction: $var;
}

@mixin align-items($var) {
  -webkit-box-align: $var;
  -ms-flex-align: $var;
  align-items: $var;
}

@mixin justify-content($var) {
  -webkit-box-pack: $var;
  -ms-flex-pack: $var;
  justify-content: $var;
}

@mixin flex-column($param...) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 $param;
  flex: 0 0 $param;
  max-width: $param;
}

@mixin img-cover {
  display: block;
  max-width: none;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
@mixin blockEllipsis($lines) {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin transition($param...) {
  -ms-transition: $param;
  -o-transition: $param;
  -moz-transition: $param;
  -webkit-transition: $param;
  transition: $param;
}

@mixin transform($param...) {
  -ms-transform: $param;
  -o-transform: $param;
  -moz-transform: $param;
  -webkit-transform: $param;
  transform: $param;
}

@mixin border-radius($param) {
  -webkit-border-radius: $param;
  border-radius: $param;
}



@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin scrollbar-vertical($width:4px, $bg-track: #E5E5E5, $bg-thumb: #EF3F36, $type: scroll) {
  overflow-y: $type;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-color: $bg-track;
  }

  &::-webkit-scrollbar {
    width: $width;
    background-color: $bg-track;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-color: $bg-thumb;
  }
}


// media query declarations

@mixin for-lg {
  @media (max-width: 1199.98px) { @content; }
}
@mixin for-md {
  @media (max-width: 991.98px) { @content; }
}
@mixin for-sm {
  @media (max-width: 767.98px) { @content; }
}
@mixin for-xs {
  @media (max-width: 575.98px) { @content; }
}
@mixin for-sp {
  @media (max-width: 374.98px) { @content; }
}
//
@mixin for-xs-up {
  @media (min-width: 576px) { @content; }
}
@mixin for-sm-up {
  @media (min-width: 768px) { @content; }
}
@mixin for-md-up {
  @media (min-width: 992px) { @content; }
}
@mixin for-lg-up {
  @media (min-width: 1200px) { @content; }
}

@mixin fullscreen {
  :-webkit-full-screen {
    @content;
  }

  :-moz-full-screen {
    @content;
  }

  :-ms-fullscreen {
    @content;
  }

  :fullscreen {
    @content;
  }
}