.breadcrumb-container {
  background-color: #fff;
  padding: 12px 0;
  ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    @include flexbox();
    -ms-align-items: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    li {
      padding: 2px 0;
      color: #161616;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      & + li {
        &:before {
          content: "/";
          display: inline-block;
          padding: 0 3px;
        }
      }
      a {
        color: #1a1a1a;
        opacity: 0.5;
        @include transition(all 0.4s ease);
        &:hover {
          color: $hover;
          opacity: 1;
        }
      }
    }
  }
}
