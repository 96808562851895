.vt-footer {
  margin-top: 112px;
  padding-bottom: 54px;
  background-color: #1a1a1a;
  position: relative;
  color: #f2f2f2;
  font-size: 14px;
  line-height: 20px;
  .subscibe-content {
    color: #1a1a1a;
    
    .content-title {
      font-size: 32px;
      line-height: (40/ 32);
      font-weight: 500;
      margin-bottom: 0;
    }
    .wrapper {
      background-color: $main;
      padding: 48px 0px;
      @include transform(translateY(-112px));
      & > .row {
        margin: 0;
      }
    }
    .desc {
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 24px;
    }
    
    .vt-form-news,
    form {
      position: relative;
      input {
        border: none;
        display: block;
        width: 100%;
        padding: 0 80px 0 32px;
        height: 64px;
      }
      .result_message {
        position: absolute;
        top: 100%;
        padding-top: 2px;
      }
      input[type="email"] {
        font-size: 16px;
        color: #4f4f4f;
        @include placeholder {
          color: #4f4f4f;
        }
      }
      input[type="submit"],
      button[type="submit"] {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 80px;
        background-color: transparent;
        border: none;
        font-size: 0;
        background-image: url("../images/icons/arrow-right-2.png");
        background-size: 14px;
        background-position: center;
        background-repeat: no-repeat;
        padding: 0;
      }
    }
    .box-content {
      padding: 0 60px;
    }
  }
  .social-title {
    font-size: 14px;
    left: 28px;
    color: #fff;
    opacity: 0.75;
    margin-top: 20px;
    margin-bottom: 24px;
  }
  .socials {
    margin-bottom: 10px;
    .social-item {
      display: inline-block;
      img {
        @include transition(transform 0.4s ease);
      }
      &:hover img {
        @include transform(scale(1.2));
      }
      & + .social-item {
        margin-left: 24px;
      }
    }
  }
  .vt-footer-content {
    margin-top: -100px;
    .container {
      padding-top: 68px;
    }
    ul {
      padding-top: 64px;
      padding-left: 0;
      list-style: none;
      li {
        margin-bottom: 24px;
        a {
          padding: 0;
          color: #fff;
          @include transition(color 0.4s ease);
          &:hover {
            color: $hover;
          }
        }
      }
    }
  }
  .logo-footer {
    max-height: 65px;
    margin: 10px 0 32px;
  }
  .copyright-footer {
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding-top: 14px;
  }
  .block-menu {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    .col-12.col-md-6 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
}
@mixin footer-lg {
  .vt-footer {
    .subscibe-content .box-content {
      padding: 0 30px;
    }
  }
}
@mixin footer-md {
  .vt-footer {
    .subscibe-content {
      .wrapper {
        padding: 30px 0;
      }
      .content-title {
        font-size: 26px;
      }
      .box-content {
        padding: 0;
      }
    }
    .block-menu {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
@mixin footer-sm {
  .vt-footer {
    margin-top: 0;
    padding-bottom: 20px;
    .subscibe-content {
      background-color: $main;
      .wrapper {
        & > .row {
          margin: 0 -15px;
        }
        padding: 24px 0;
        @include transform(translateX(0));
        .content-title {
          font-size: 24px;
          margin-bottom: 24px;
        }
      }
      form input {
        padding-left: 15px;
      }
    }
    .vt-footer-content {
      margin-top: 0;
      .container {
        padding-top: 0;
      }
      ul {
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 32px;
        li {
          margin-bottom: 0;
          & + li {
            margin-top: 20px;
          }
        }
      }
      .socials {
        margin-bottom: 32px;
      }
      .logo-footer {
        max-width: 50%;
        max-height: 40px;
        margin-top: 32px;
        margin-bottom: 24px;
      }
    }
  }
}
